import React from 'react';
import { MotionWrap } from '../../../wrapper';
import Settings from '../../../wrapper/Settings';
import './Spravy.scss';

const Zmluvy = () => {
  return (
    <div className="app__header">
      <h1 className="blog">Správy</h1>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZjHKwZNXddoc092u086YlRI3RIwzJvTeoy" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2023
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZS9EnZGLIpjo6M4o539cQhfXq277bvI9Ak" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2022
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZeUlWZkduFAk4QuLYiP178jEsBvYAoGJkV" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2021
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZYElWZTXyt9voxglHEzHjoVP6hKz5jYCJV" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2020
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZMKlWZW1uFNf6XBtfS4kwtbVTG15TV8rNV" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2019
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZSElWZ4EPg2kVmLY8H9iJhyP4KmX39dOxy" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2018
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZ0ElWZUhFGlECyIfLKndrWLsCskmKLegnX" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2017
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZyElWZlJeQ17XpwEy4wQC0fxYUAFXqyiXX" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2016
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZc6lWZQivHKxzrbGJEnV745dUSy5oTo0eV" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2015
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZG6lWZerNGwXyzMFRoeTxs2GsrhkEkvcey" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2014
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZA6lWZO3fkp3USzDF8ck8RQP3CAhaLnUyV" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2013
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZ66lWZIvHJH2jtnT8GukiAXS553JYWEoy0" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2012
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZx6lWZvrwJ7ihL2rhUp0Wsny3TLXuf5zr7" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2011
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZT6lWZ7fqk20VQo9LndOI0skLb7F3cmqay" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2010
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZM6lWZLR7TeEa4wPBOzExtolxjTkKNBx0k" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2009
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZn6lWZN89RCqpAxiQve8e5qljTYSy2iP2k" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2008
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZD6lWZ3OAtu78AnlbiSBdvTpFWAVk8J4Vy" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2007
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZs6lWZDmLibljCgVLVYxW5cFqK4jp9HtVX" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2006
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZf6lWZeMnsfpfecwHignJqbnXXWVPHJpiX" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2005
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZh6lWZAv9BWbg8YTFjaw2dsgVLlQJsqWmy" className="no-link-effect" target="_blank" rel="noopener noreferrer">
            Správa o výchovno-vzdelávacej činnosti 2004
          </a>
        </article>
      </div>
    </div>
  );
};

export default Settings(MotionWrap(Zmluvy, 'app__skills'), 'skills', 'app__whitebg');





