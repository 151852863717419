import React, { useState, useEffect } from 'react';
import { MotionWrap, AppWrap } from '../../../wrapper';
import Settings from '../../../wrapper/Settings';
import './Questions.scss';


const Prihlaska = () => {
 

  return (
    <div className="app__header Otazky">
      <h1 className="blog">PRIJÍMACIE KONANIE</h1>
      <p>
        Minister školstva, vedy, výskumu a športu Slovenskej republiky podľa § 150 ods. 8 a § 161k zákona č. 245/2008 Z. z. o výchove a vzdelávaní (školský zákon) a o zmene a doplnení niektorých zákonov v znení neskorších predpisov určil prijímacie konanie žiakov základných umeleckých škôl nasledovne:
        Riaditeľ základnej umeleckej školy zverejní na webovom sídle základnej umeleckej školy a verejne dostupnom mieste termín konania prijímacích skúšok najneskôr mesiac pred konaním prijímacieho konania a termín dokedy je potrebné doručiť vyplnenú prihlášku príslušnej základnej umeleckej škole buď elektronicky alebo poštou. 
        V súlade s týmto riaditeľka školy zverejňuje termíny prijímacieho konania:
        <br />
        <span className="bold-text">1. termín  18.4.2024 o 16.00 hod</span>
        <br />
        <span className="bold-text">2. termín  zatiaľ neplánujeme</span>
        <br />
        <a href="https://www.minedu.sk/prijimanie-ziakov-na-studium/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#00f' }}>Prihlášku na štúdium</a>
        <br />
        <a href="https://www.izus.sk/prihlaska_do_zus/nova/?zus=766" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#00f' }}>Prihlášku na štúdium</a>
        <br />
        Predmetom prijímacieho konania je kresba postavy, postáv.
       
      </p>
    </div>
  );
};

export default Settings(MotionWrap(Prihlaska, 'app__skills', 'Prihlaska'), 'Prihlaska', 'app__whitebg');
